@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
body::-webkit-scrollbar {
  display: none;
} */

canvas {
  width: 100% !important;
  height: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::selection {
  background: #fbe773; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #fbe773; /* Gecko Browsers */
}

button:focus {
  outline: none !important;
}

/* .MuiFormLabel-root.Mui-focused {
  color: #ed7273 !important;
} */
/* .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ed7273 !important;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a,
a:hover {
  color: #77E0FF;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Full Calendar CSS */

* {
  font-family: "Nunito";
}

.fc-day-today {
  background: rgba(119, 224, 255, 0.2) !important;
}

/* .fc-event-time {
  display: none !important;
} */

.fc-event-main .fc-event-time {
  display: none !important;
}

.fc-event-title {
  white-space: initial !important;
}

.fc-toolbar .fc-prev-button,
.fc-toolbar .fc-prev-button:focus,
.fc-toolbar .fc-prev-button:active,
.fc-toolbar .fc-next-button,
.fc-toolbar .fc-next-button:focus,
.fc-toolbar .fc-next-button:active,
.fc-toolbar .fc-today-button {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none !important;
  border: none !important;
  text-transform: uppercase !important;
}

.fc-toolbar .fc-prev-button:hover,
.fc-toolbar .fc-next-button:hover,
.fc-toolbar .fc-today-button:hover {
  background-color: #77E0FF !important;
  color: white !important;
  box-shadow: none !important;
  border: none !important;
}

.fc-toolbar .fc-today-button:disabled {
  display: none;
}

