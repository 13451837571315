/* Full Calendar CSS */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Nunito";
}

.fc-day-today {
  background: rgba(119, 224, 255, 0.2) !important;
}

/* .fc-event-time {
  display: none !important;
} */

.fc-event-main .fc-event-time {
  display: none !important;
}

.fc-event-title {
  white-space: initial !important;
}

.fc-toolbar .fc-prev-button,
.fc-toolbar .fc-prev-button:focus,
.fc-toolbar .fc-prev-button:active,
.fc-toolbar .fc-next-button,
.fc-toolbar .fc-next-button:focus,
.fc-toolbar .fc-next-button:active,
.fc-toolbar .fc-today-button {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none !important;
  border: none !important;
  text-transform: uppercase !important;
}

.fc-toolbar .fc-prev-button:hover,
.fc-toolbar .fc-next-button:hover,
.fc-toolbar .fc-today-button:hover {
  background-color: #77E0FF !important;
  color: white !important;
  box-shadow: none !important;
  border: none !important;
}

.fc-toolbar .fc-today-button:disabled {
  display: none;
}
